import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/materialdesignicons.min.css';
import './assets/scss/style.scss';
import Index from './pages';
import AboutUs from './pages/aboutus';
import CoachProfile from './pages/coach-profile';
import Coaches from './pages/coaches';
import Comingsoom from './pages/comingsoon';
import ContactUs from './pages/contactus';
import Error from './pages/error';
import JobDetail from './pages/job-detail';
import Jobs from './pages/jobs';
import LockScreen from './pages/lock-screen';
import Login from './pages/login';
import Maintenance from './pages/maintenance';
import Privacy from './pages/privacy';
import Profile from './pages/profile';
import ResetPassword from './pages/reset-password';
import Signup from './pages/signup';
import Terms from './pages/terms';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from './store/slice/job-slice';
import { getCoaches } from './store/slice/coach-slice';
import JobForm from './pages/job-form';
import Followers from './pages/followers';
import EditCoachProfileByAdmin from './pages/EditCoachProfileByAdmin';
import Messages from './pages/messages';
import Conversations from './pages/conversations';
import { isLoggedIn } from './helpers/utils';

const App = () => {
  const dispatch = useDispatch();
  const { status: jobStatus } = useSelector((state) => state.job);
  const { status: coachStatus } = useSelector((state) => state.coach);

  useEffect(() => {
    if (jobStatus === 'idle') dispatch(getJobs());
    if (coachStatus === 'idle') dispatch(getCoaches());
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' exact element={<Index />} />
        <Route path='/index' element={<Index />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path='/coaches' element={<Coaches />} />

        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/contactus' element={<ContactUs />} />

        <Route path='/coach-profile/:id' element={<CoachProfile />} />
        <Route path='/job-detail/:id' element={<JobDetail />} />

        {isLoggedIn() ? (
          <>
            <Route path='/followers' element={<Followers />} />

            <Route path='/profile' element={<Profile />} />
            <Route path='/job-form' element={<JobForm />} />
            <Route path='/job-form/:id' element={<JobForm />} />

            <Route
              path='/edit-profile-by-admin/:id'
              element={<EditCoachProfileByAdmin />}
            />
            <Route path='/messages' element={<Conversations />} />
            <Route path='/message/:id' element={<Messages />} />
          </>
        ) : (
          <>
            <Route path='/followers' element={<Navigate to='/login' />} />
            <Route path='/profile' element={<Navigate to='/login' />} />
            <Route path='/job-form' element={<Navigate to='/login' />} />
            <Route path='/job-form/:id' element={<Navigate to='/login' />} />
            <Route
              path='/edit-profile-by-admin/:id'
              element={<Navigate to='/login' />}
            />

            <Route path='/messages' element={<Navigate to='/login' />} />
            <Route path='/message/:id' element={<Navigate to='/login' />} />

            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/reset-password' element={<ResetPassword />} />
          </>
        )}

        <Route path='*' element={<Error />} />
      </Routes>
    </>
  );
};

export default App;
